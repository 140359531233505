import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { fetchUserBySub } from '../../../components/DataStore/UserService';
import Authenticate from '../../../components/Identity/Authenticate';
import { getCurrentUser } from '../../../components/Shared/Services/AuthService';
import { isEligibleToHideCourse } from '../../../services/utils';

const GrowthToolsPage:React.FC = () => {
  const getActiveUserData = async () => {
    const currentUser = await getCurrentUser();
    const subId = currentUser.attributes.sub;
    const activeUserId = currentUser.attributes?.['custom:active_user'];
    const userRes = await fetchUserBySub(subId, activeUserId);
    return userRes;
  };

  const navigateBasedOnUserRole = async () => {
    const userProfile = await getActiveUserData();
    if (isEligibleToHideCourse(userProfile?.roles?.[0])) {
      navigate('/admin/growth-tools/assign-courses/');
      return;
    }
    navigate('/admin/overview/');
  };

  useEffect(() => {
    navigateBasedOnUserRole();
  }, []);

  return <></>;
};

export default Authenticate(GrowthToolsPage);
